<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
onMounted(() => {
  // load/init chatbot service; structure/values supplied by SalesForce
  // useSalesForceService();
});
</script>

<style scoped></style>
