export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0 maximum-scale=1.0"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.png"},{"rel":"stylesheet","href":"https://dbrd449anfbv4.cloudfront.net/insync_chat_button.css"}],"style":[],"script":[{"src":"https://cdn.optimizely.com/js/25691410171.js"},{"innerHTML":"\n      (function (w, d, s, l, i) {\n        w[l] = w[l] || [];\n        w[l].push({ \"gtm.start\": new Date().getTime(), event: \"gtm.js\" });\n        var f = d.getElementsByTagName(s)[0],\n          j = d.createElement(s),\n          dl = l != \"dataLayer\" ? \"&l=\" + l : \"\";\n        j.async = true;\n        j.src = \"https://sstm.online.asu.edu/gtm.js?id=\" + i + dl;\n        f.parentNode.insertBefore(j, f);\n      })(window, document, \"script\", \"dataLayer\", \"GTM-PQ5N2KP\");\n    ","defer":true}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0 maximum-scale=1.0","htmlAttrs":{"lang":"en"}}

export const appLayoutTransition = true

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false